.bri-horizontal-stack {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
}

.bri-horizontal-stack-item {
    float:none;

    /* xs - Extra small devices (portrait phones, less than 576px) */
    min-width: 280px; /* Set the desired min-width for xs devices */
    max-width: 285px; /* Set the desired max-width for xs devices */
}

/* sm - Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .bri-horizontal-stack-item {
        min-width: 280px; /* Set the desired min-width for sm devices */
        max-width: 380px; /* Set the desired max-width for sm devices */
    }
}

/* md - Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .bri-horizontal-stack-item {
        min-width: 280px; /* Set the desired min-width for md devices */
        max-width: 495px; /* Set the desired max-width for md devices */
    }
}

/* lg - Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .bri-horizontal-stack-item {
        min-width: 280px; /* Set the desired min-width for lg devices */
        max-width: 580px; /* Set the desired max-width for lg devices */
    }
}

/* xl - Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .bri-horizontal-stack-item {
        min-width: 280px; /* Set the desired min-width for xl devices */
        max-width: 650px; /* Set the desired max-width for xl devices */
    }
}

/* xxl - Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {
    .bri-horizontal-stack-item {
        min-width: 280px; /* Set the desired min-width for xxl devices */
        max-width: 700px; /* Set the desired max-width for xxl devices */
    }
}
