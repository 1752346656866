.bri-action-bar-commands {
    display: none;
}
.bri-action-bar-container:hover .bri-action-bar-commands {
    display: flex;
}
.bri-action-bar-lock-tag {
    margin: 5px;
    display: none;
    opacity: 0.65;
}
.bri-action-bar-container *[readonly]~.bri-action-bar-lock-tag {
    display: block;
}
.bri-action-bar-container .bri-action-bar-unlock-tag {
    margin: 5px;
    display: block;
    opacity: 0.65;
}
.bri-action-bar-container *[readonly]~.bri-action-bar-unlock-tag {
    display: none;
}
